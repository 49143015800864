import React from "react";
import { Popper, Fade, Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "theme";

export default function PasswordRulesPopper({ open, anchorEl }: any) {
  const cx = useStyles();
  return (
    <Popper open={open} className={cx.root} anchorEl={anchorEl} placement="bottom-start" transition>
      {({ TransitionProps }: any) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box mt={1}>
            <Paper>
              <Box p={2}>
                <Box color={theme.palette.primary.dark} mb={1}>
                  Password requirements:
                </Box>
                • Use at least 8 characters
                <br />• Special characters (!@#$%^&*)
                <br />• Use at least 1 lower letter
                <br />• Use at least 1 number
              </Box>
            </Paper>
          </Box>
        </Fade>
      )}
    </Popper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 10,
  },
}));
