import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY as string,
};

export const initGTM = () => {
  TagManager.initialize(tagManagerArgs);
};

export default initGTM;
