import React from "react";
import clx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Box } from "@material-ui/core";

interface IProps {
  Icon: any;
  label?: string | any;
  className?: any;
}

function Badge({ Icon, label, className }: IProps) {
  const cx = useStyles();
  const cn = clx(cx.root, className || {});
  if (!label) return null;
  return (
    <Box>
      <Chip icon={<Icon style={{ color: "white" }} />} size="medium" label={label} className={cn} />
    </Box>
  );
}

Badge.defaultProps = {
  Icon: () => <></>,
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    flex: 1,
    height: 30,
    width: (props: any) => props.width || 150,
    textTransform: "uppercase",
    "& .MuiChip-label": {
      color: "white",
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

export default Badge;
