import React, { Dispatch } from "react";
import { ContactProperties, ShopProperties } from "../../@types/graphqlTypes";

export interface IDataState {
  email?: any;
  auth0User?: any;
  user: {
    data: Partial<ContactProperties & { mobileAndEmailVerified?: boolean }>;
    loading: boolean;
    error: any;
  };
  shop: {
    data: Partial<ShopProperties>;
    loading: boolean;
    error: any;
  };
  isLoggedIn?: boolean | any;
  token?: string;
  shop_id?: string | number | undefined;
  error?: any;
  dispatch?: Dispatch<any | any> | any;
}

export const defaultContext = {
  isLoggedIn: false,
  email: undefined,
  shop_id: undefined,
  token: undefined,
  auth0User: undefined,
  user: {
    data: {
      id: "",
    },
    loading: true,
    error: null,
  },
  shop: {
    data: {
      shop_id: 0,
    },
    loading: true,
    error: null,
  },
  dispatch: () => null,
} as IDataState;

export const DataContext = React.createContext<IDataState>(defaultContext);
