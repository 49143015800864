import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgPreview: {
      maxWidth: '100%',
    },
  })
);

export const ImageDialogViewer = ({ title, src, open, close }: any) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onBackdropClick={close}
      aria-labelledby="responsive-dialog-title"
    >
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" align="center">
          {title}
        </Typography>
      </Toolbar>
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <img src={src} className={classes.imgPreview} alt={title} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialogViewer;
