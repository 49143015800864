import React, { ReactNode } from "react";
import { Typography, Box, Divider, Paper, BoxProps } from "@material-ui/core";
import LoadingBox from "components/LoadingBox/LoadingBox";
import If from "utils/If";
interface IPageLayout extends BoxProps {
  children: ReactNode;
  loading?: boolean;
  title?: any;
  width?: string | number;
  component?: any;
  fullMode?: boolean;
  ItemRight?: any;
  ItemLeft?: any;
}

export default function PageLayout({
  children,
  loading = false,
  width = 1,
  title = "",
  component = Paper,
  fullMode = false,
  ItemLeft = () => <></>,
  ItemRight = () => <></>,
  ...rest
}: IPageLayout) {
  return (
    <Box
      height="100%"
      p={fullMode ? [0] : [2, 4, 4]}
      pt={fullMode ? [0] : [2, 4, 4]}
      width={[1, 1, 1, width]}
      mx="auto"
      mb={2}
      borderRadius={4}
      position="relative"
      component={component}
      className="page-layout"
      {...rest}
    >
      <If test={title}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <If test={ItemLeft}>
              <ItemLeft />
            </If>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          </Box>
          <If test={ItemRight}>
            <ItemRight />
          </If>
        </Box>
        <Divider style={{ marginBottom: 15 }} />
      </If>

      {children}
      {loading && <LoadingBox loading={loading} />}
    </Box>
  );
}
