import React, { useState, ChangeEvent, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tabs, Tab } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import cls from "clsx";
import { grey } from "@material-ui/core/colors";

export interface Itab {
  label: string;
  value: string;
  hidden: boolean;
}

interface ITab {
  tabs: Itab[];
  defaultPath?: string;
  withRoute?: boolean;
  orientation?: "horizontal" | "vertical";
  mainNav?: boolean;
  title?: string;
  rootClass?: any;
  longestPathCheck?: boolean;
}

function PortalTab(props: ITab | any) {
  const {
    location,
    defaultPath,
    tabs,
    title,
    orientation,
    rootClass,
    mainNav,
    longestPathCheck,
  } = props;
  const classes = useStyles();

  const [value, setValue] = useState(location.pathname || defaultPath || "");

  useEffect(() => {
    if (mainNav || longestPathCheck) {
      let longestMatch = "";
      tabs.forEach((item: any) => {
        if (location.pathname.includes(item.value) && item.value.length > longestMatch.length)
          longestMatch = item.value;
      });
      setValue(longestMatch);
    } else {
      setValue(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleChange = (event: ChangeEvent<{}>, newValue: any) => {
    props.withRoute && props.history.push(newValue);
  };

  return (
    <Box>
      {title && (
        <Box py={2.5} px={3} fontSize="h6.fontSize" fontWeight={600}>
          {title}
        </Box>
      )}
      <Tabs
        value={value}
        orientation={orientation}
        onChange={handleChange}
        variant="scrollable"
        aria-label="scrollable force tabs"
        className={cls(
          classes.root,
          orientation === "vertical" ? classes.verticalTabsRoot : {},
          rootClass ? rootClass : {}
        )}
        classes={{
          indicator: classes.indicator,
        }}
      >
        {tabs?.map((tab: Itab) => (
          <Tab
            value={tab.value}
            key={tab.value}
            label={tab.label}
            classes={{
              wrapper: cls(classes.wrapper, props.mainNav ? classes.wrapperMain : {}),
              root: classes.tabRoot,
            }}
            style={{ display: tab?.hidden ? "none" : "block" }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

PortalTab.defaultProps = {
  withRoute: false,
  mainNav: false,
  orientation: "horizontal",
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButtonBase-root": {
      textAlign: "left",
    },
    minWidth: "130px",
  },
  verticalTabsRoot: {
    "& .MuiButtonBase-root": {
      padding: "20px 24px",
      maxWidth: "100%",
      borderTop: "1px solid #E6E6E6",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      background: grey[100],
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
  },
  wrapper: {
    fontSize: "14px",
    textTransform: "capitalize",
    alignItems: "flex-start",
  },
  wrapperMain: {
    alignItems: "center",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    left: 0,
  },
  tabRoot: {
    minWidth: "auto",
    padding: `${theme.spacing(1.75)}px ${theme.spacing(2)}px`,
    opacity: 1,
  },
}));
export default withRouter(PortalTab);
