import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Container from "@material-ui/core/Container";
import { Box, Button } from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ReactComponent as Carrefour } from "assets/svgs/carrefour.svg";
import Link from "../Link";
import UserAvatar from "../UserAvatar";
import theme from "theme";

import If from "utils/If";
import { useLocation } from "react-router-dom";

export const Nav = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const classes = useStyles();
  const { search, pathname } = useLocation();
  const sellerFlow = pathname.includes("/seller");
  const portalFlow = pathname.includes("/portal");

  let url = "/";

  if (sellerFlow) url = "/seller";
  if (portalFlow) url = "/portal";

  const showLoginButton =
    !isAuthenticated && !pathname.includes("login") && !pathname.includes("register");

  return (
    <>
      <Box height="6px" bgcolor={theme.palette.primary.main}></Box>
      <nav className={classes.root}>
        <Box display="flex">
          <Container maxWidth={"lg"}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Link to={{ pathname: url, search }}>
                <Box className={classes.logo}>
                  <Carrefour />
                </Box>
              </Link>
              <Box display="flex" justifyContent="center" alignItems="center">
                <If test={!isLoading && showLoginButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      loginWithRedirect({
                        redirectUri: window.location.origin + "/portal",
                      })
                    }
                  >
                    Login
                  </Button>
                </If>

                <If test={isAuthenticated && sellerFlow}>
                  <Link to="/portal">
                    <Button color="secondary">Seller Portal</Button>
                  </Link>
                </If>

                <If test={!isLoading && isAuthenticated && !sellerFlow}>
                  <UserAvatar />
                </If>
              </Box>
            </Box>
          </Container>
        </Box>
      </nav>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      background: "white",
    },

    logo: {
      width: 142,
      display: "inline-block",
    },
  })
);

export default Nav;
