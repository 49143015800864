import React from "react";
import Select from "./Select";
import Text from "./Text";
import File from "./File";
import Password from "./Password";
import TextAreaAttachment from "./TextAreaAttachment";

export const Field = ({
  control,
  type,
  name,
  label,
  register,
  validation,
  error,
  controlProps,
  options,
  inputAdornment,
  formFiles,
  value,
  url,
  tooltip,
  defaultValue,
  allowedChars,
  multiline,
  rows,
  onChange,
  disabled,
}: any) => {
  return (
    <div>
      {(() => {
        switch (type) {
          case "TEXT":
            return (
              <Text
                name={name}
                label={label}
                inputAdornment={inputAdornment}
                register={register}
                validation={validation}
                controlProps={controlProps}
                error={error}
                tooltip={tooltip}
                allowedChars={allowedChars}
              />
            );
          case "TEXTAREA":
            return (
              <Text
                name={name}
                label={label}
                inputAdornment={inputAdornment}
                register={register}
                validation={validation}
                controlProps={controlProps}
                error={error}
                tooltip={tooltip}
                allowedChars={allowedChars}
                multiline={multiline}
                rows={rows}
              />
            );
          case "TEXTAREA_ATTACHMENT":
            return (
              <TextAreaAttachment
                name={name}
                label={label}
                control={control}
                validation={validation}
                error={error}
                rows={rows}
              />
            );
          case "PASSWORD":
            return (
              <Password
                name={name}
                label={label}
                validation={validation}
                error={error}
                register={register}
              />
            );
          case "SELECT":
            return (
              <Select
                name={name}
                label={label}
                control={control}
                options={options}
                error={error}
                validation={validation}
                defaultValue={defaultValue}
                onChange={onChange}
                disabled={disabled}
              />
            );
          case "FILE":
            return (
              <File
                value={value}
                url={url}
                name={name}
                label={label}
                control={control}
                error={error}
                validation={validation}
                formFiles={formFiles}
                tooltip={tooltip}
                disabled={disabled}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Field;
