import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

if (process.env.REACT_APP_INSIGHTS_KEY) {
  const browserHistory = createBrowserHistory({ basename: "" });
  var reactPlugin = new ReactPlugin();
  var appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
      loggingLevelConsole: 1,
    },
  });
  appInsights.loadAppInsights();
}
