import React from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import { Box } from "@material-ui/core";
import { Notification } from "components";
import { useHistory } from "react-router-dom";
import If from "utils/If";

export default function MainLayout({ children }: any) {
  const history = useHistory();
  const isRegistration = !history.location.pathname.includes("/portal");
  return (
    <>
      <Box display="flex" flexDirection="column" height="100%" bgcolor="#fafafa">
        <Box flex="1 0 auto">
          <Nav />
          <If test={isRegistration}>
            <Notification />
          </If>
          {children}
        </Box>
        <Footer />
      </Box>
    </>
  );
}
