import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

interface IProps {
  columns: string[];
  rows: any[];
  rowOnClick?: any;
  sortby?: any;
  onSort?: (columnName: string, value: "ASC" | "DESC") => void;
}

const TableHeadCell = ({ columnName, sortby, onSort }: any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClick = (value: string) => () => {
    setAnchorEl(null);
    onSort(columnName, value);
  };

  return (
    <TableCell key={columnName} className={classes.tableCellHead}>
      {columnName}
      {sortby && (
        <Box display="inline-block" ml={0.5}>
          <IconButton aria-label="sort" className={classes.sortBtn} onClick={handleClick}>
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {sortby.map((item: any, index: number) => (
              <MenuItem onClick={onClick(item.value)} key={`sortItem${index}`}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </TableCell>
  );
};

function Table({ rows, columns, rowOnClick, sortby, onSort }: IProps) {
  const classes = useStyles();

  const rowsColumnsMapping = rows.map((row: any) => {
    return columns?.map((column: string) => row[column]);
  });

  return (
    <>
      <MuiTable className={classes.table} aria-label="table">
        <TableHead>
          <TableRow>
            {columns?.map((columnName: string) => (
              <TableHeadCell
                columnName={columnName}
                sortby={sortby?.[columnName]}
                onSort={onSort}
                key={columnName}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsColumnsMapping?.map((row: any, key: any) => (
            <TableRow
              key={"TableRow-" + key}
              className={classes.tableRow}
              onClick={(e) => rowOnClick(e, rowsColumnsMapping[key][0], key)}
            >
              {row.map((rowItem: any) => (
                <TableCell
                  key={"TableCell-" + rowItem}
                  component="th"
                  scope="row"
                  className={classes.tableCellBody}
                >
                  {rowItem}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </>
  );
}

Table.defaultProps = {
  rowOnClick: () => {},
};

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      minWidth: 650,
      overflow: "auto",
    },
    tableCellHead: {
      fontSize: "1rem",
      color: "#4D4D4D",
      fontWeight: 500,
      textTransform: "capitalize",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderBottomColor: "#E6E6E6",
    },
    tableCellBody: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderBottomColor: "#E6E6E6",
    },
    tableRow: {
      textTransform: "capitalize",
      "&:hover": {
        background: grey[100],
        cursor: "pointer",
      },
    },
    sortBtn: {
      padding: 0,
    },
  })
);

export default Table;
