import React from "react";
import { Controller } from "react-hook-form";
import TextArea from "components/TextArea";
import { isExisty } from "utils/helpers.util";

export default function TextAreaAttachment({
  name,
  control,
  label,
  error,
  validation = {},
  rows = 4,
}: any) {
  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      onChange={([value]) => ({ value })}
      defaultValue={{ value: "", files: [] }}
      as={
        <TextArea
          label={label}
          rows={rows}
          error={error && error.message}
          required={isExisty(validation.required)}
        />
      }
    />
  );
}
