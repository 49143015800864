import React, { useEffect, useState } from "react";
import { Grid, Button, Box, Typography, Divider } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { isExisty } from "utils/helpers.util";
import Field from "./Field";

type IErrorFeild = { code: string; field: string };
interface IProps {
  schema: any[];
  onSubmit: (data: any, other?: any) => void;
  submitBtnTxt: string;
  errorFields?: any[];
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  fullWidth?: boolean;
  buttonDisabled?: boolean;
  buttonWidth?: string;
  buttonClassName?: any;
  onInit?: (reset: any) => void;
}

export default function FormRenderer({
  schema = [],
  onSubmit,
  submitBtnTxt = "Submit",
  errorFields,
  fullWidth = true,
  onChange,
  onBlur,
  buttonDisabled = false,
  buttonWidth = "100%",
  buttonClassName,
  onInit,
}: IProps) {
  const [formFiles, setformFiles] = useState<any>({});
  const { register, handleSubmit, errors, control, setError, reset } = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    onInit && onInit(reset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormChange = (e: any) => {
    const { value, type, name } = e.target;
    if (onChange) onChange(e);

    if (type === "file") {
      if (!Object.values(formFiles).includes(value) || !value) {
        const files = {
          ...formFiles,
          [name]: value,
        };
        setformFiles(files);
      }
    }
  };

  React.useEffect(() => {
    errorFields?.map((error: IErrorFeild) => {
      setError(error.field, "notMatch");
      return error.field;
    });
  }, [errorFields, setError]);

  return (
    <form
      onSubmit={handleSubmit((e) => onSubmit(e, reset))}
      onChange={onFormChange}
      onBlur={onBlur}
    >
      {schema.map((group: any) => (
        <div key={group.label}>
          {group.showGroupLabel && (
            <Box mb={2}>
              <Typography variant="h6" component="h5" color="textSecondary">
                {group.label}
              </Typography>
            </Box>
          )}
          <Grid container spacing={3} direction={group?.direction || "row"}>
            {group.fields?.map((field: any, key: any) => (
              <React.Fragment key={field.name + key}>
                {field.divider && (
                  <Box mx={2} mt={2} mb={-8} width="100%" key={`${key}-divider`}>
                    <Divider />
                  </Box>
                )}

                <Grid item {...field.size} key={field.name}>
                  {field.title && (
                    <Box mb={1.5} fontSize="body1.fontSize" fontWeight={600}>
                      {field.title}
                    </Box>
                  )}
                  <Field
                    {...field}
                    register={register}
                    control={control}
                    passwordInstructions={field.passwordInstructions}
                    error={isExisty(errors) ? errors[field.name] : null}
                    formFiles={formFiles}
                    onChange={onFormChange}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          {group.showGroupLabel && (
            <Box my={2}>
              <Divider />
            </Box>
          )}
        </div>
      ))}
      <Box mt={3} width={buttonWidth} className={buttonClassName ? buttonClassName : ""}>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          fullWidth={fullWidth}
          disabled={buttonDisabled}
        >
          {submitBtnTxt}
        </Button>
      </Box>
    </form>
  );
}
