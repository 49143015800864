import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { MainLayout } from "../components";
import ErrorBoundary from "./ErrorBoundary";
import DynamicLoader from "./DynamicLoader";
import DataProvider from "./DataProvider";
import AuthenticatedRoute from "./AuthenticatedRoute";

const Landing = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Checkout" */
      /* webpackPrefetch: true */
      "pages/Landing"
    )
);

const Register = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Checkout" */
      /* webpackPrefetch: true */
      "pages/Register"
    )
);

const AccountDetails = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Checkout" */
      /* webpackPrefetch: true */
      "pages/AccountDetails"
    )
);

const OTP = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Checkout" */
      /* webpackPrefetch: true */
      "pages/OTP"
    )
);

const NewAccountConfirmation = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Checkout" */
      /* webpackPrefetch: true */
      "pages/NewAccountConfirmation"
    )
);
const SellerTracker = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "SellerTracker" */
      /* webpackPrefetch: true */
      "pages/SellerTracker"
    )
);

const PageNotFound = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Checkout" */
      /* webpackPrefetch: true */
      "pages/PageNotFound"
    )
);

const SellerPortal = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "SellerPortal" */
      /* webpackPrefetch: true */
      "pages/SellerPortal"
    )
);

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <DataProvider>
          <MainLayout>
            <Switch>
              <Route exact path="/">
                <Redirect to="seller" />
              </Route>
              <Route exact path="/login">
                <Redirect to="" />
              </Route>
              <Route component={Landing} path="/seller" exact />
              <Route component={Landing} path="/seller" exact />
              {/* seller registration */}
              <Route component={Register} path="/seller/register" />
              <Route component={AccountDetails} path="/seller/details/:email" />
              <Route component={OTP} path="/seller/otp" />
              <Route component={NewAccountConfirmation} path="/seller/confirmation" />
              {/* seller portal */}
              <AuthenticatedRoute component={SellerPortal} path="/portal/:page?/:subpage?" />
              <Route component={PageNotFound} path="/error" />
              <Route component={PageNotFound} path="/seller/error" />

              {/* seller tracker */}
              <Route component={SellerTracker} path="/seller/TIvEriNfUtigNEamELSimpEArtEl" />
              <Route component={PageNotFound} />
            </Switch>
          </MainLayout>
        </DataProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Router;
