import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const Auth0ProviderWithHistory = ({ children }: any) => {
  const domain = process.env.REACT_APP_AUTH_ENDPOINT as string;
  const clientId = process.env.REACT_APP_AUTH_ID as string;
  const audience = process.env.REACT_APP_AUTH_AUDIENCE as string;

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience} // auth0 api
      connection="seller-db"
      scope="update:current_user_metadata create:tickets read:tickets"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
