import React from "react";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  FormHelperText,
  TextField,
  FilledInput,
} from "@material-ui/core";
import { isExisty } from "utils/helpers.util";
import ConditionalWrapper from "components/ConditionalWrapper";
import CustomTooltip from "components/CustomTooltip";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  name: string;
  label: String;
  validation?: any;
  error?: any;
  inputAdornment?: string;
  register: any;
  controlProps: any;
  passwordInstructions?: boolean;
  tooltip?: string;
  allowedChars?: RegExp;
  multiline?: boolean;
  rows?: number;
}

export default function Text({
  name,
  label,
  validation,
  error,
  inputAdornment,
  register,
  controlProps,
  tooltip,
  allowedChars,
  multiline,
  rows,
}: Props) {
  const cx = useStyles();
  const checkAllowedChars = (e: React.KeyboardEvent) => {
    if (allowedChars) {
      if (!allowedChars.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  return (
    <>
      <ConditionalWrapper
        condition={isExisty(tooltip)}
        wrapper={(children: any) => (
          <CustomTooltip title={tooltip} aria-label={tooltip} placement="bottom" arrow>
            {children}
          </CustomTooltip>
        )}
      >
        {multiline ? (
          <>
            <TextField
              className={cx.root}
              variant="filled"
              multiline={true}
              rows={rows}
              fullWidth
              onKeyPress={allowedChars && checkAllowedChars}
              name={name}
              label={label}
              error={!!error}
              inputRef={register({ ...validation })}
              required={isExisty(validation) && isExisty(validation.required)}
            />
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        ) : (
          <FormControl fullWidth variant="filled">
            <InputLabel
              htmlFor={name}
              error={isExisty(error)}
              required={isExisty(validation) && isExisty(validation.required)}
            >
              {label}
            </InputLabel>
            <FilledInput
              id={name}
              startAdornment={
                isExisty(inputAdornment) && (
                  <InputAdornment position="start">{inputAdornment}</InputAdornment>
                )
              }
              {...controlProps}
              name={name}
              inputRef={register({ ...validation })}
              error={!!error}
              onKeyPress={allowedChars && checkAllowedChars}
              multiline={multiline}
              rows={rows}
              className={cx.root}
            />
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      </ConditionalWrapper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F3F5F8 !important",
    "& .MuiInputBase-root": {
      backgroundColor: "transparent",
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.main,
    },
  },
}));
