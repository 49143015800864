import React, { Component } from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import notify from "./Notification.service";
import Alert from "@material-ui/lab/Alert";
import { Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";

interface INotifystate {
  show: boolean;
  content: any;
  type: "error" | "success" | "info" | "warning" | undefined;
  onClose: any;
}

export const CustomAlert = withStyles((theme: Theme) => ({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}))(Alert);

class Notification extends Component<any> {
  unlisten: any;
  constructor(props: any) {
    super(props);
    notify.register(this);
  }

  state: INotifystate = {
    show: false,
    content: null,
    type: "error",
    onClose: () => {},
  };

  show = (content: any, type = "error", onClose = () => {}) => {
    this.setState({ content, show: true, type, onClose });
    this.unlisten = this.props.history.listen((location: any, action: any) => {
      this.hide();
    });
  };

  hide = () => {
    if (this.state.show && this.unlisten) this.unlisten();
    this.setState({ show: false, content: null });
    if (this.state.onClose) this.state.onClose();
  };

  componentWillUnmount() {
    if (this?.unlisten) this.unlisten();
  }

  render() {
    const { show, content, type } = this.state;

    if (!show) return null;

    return (
      <Box p={4} width={3 / 4} mx="auto" my={0}>
        {Array.isArray(content) ? (
          content.map((message, key) => (
            <CustomAlert severity={type} onClose={this.hide} key={`notification-${type}-${key}`}>
              {message}
            </CustomAlert>
          ))
        ) : (
          <CustomAlert severity={type} onClose={this.hide}>
            {content}
          </CustomAlert>
        )}
      </Box>
    );
  }
}

export default withRouter(Notification);
