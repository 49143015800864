import React, { useState, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { CheckCircle, Error, Attachment } from "@material-ui/icons";
import ImageDialogViewer from "../ImageDialogViewer";
import theme from "theme";
import CustomTooltip from "components/CustomTooltip";
import ConditionalWrapper from "components/ConditionalWrapper";
import { isExisty } from "utils/helpers.util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: any) => ({
      border: "1px dashed",
      borderColor:
        props.error && !props.file?.name ? theme.palette.error.main : theme.palette.grey[300],
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
    }),
    pendingIcon: {
      color: theme.palette.grey[400],
    },
    checkIcon: {
      color: theme.palette.success.main,
    },
    input: {
      display: "none",
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
    selectedFileChip: {
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginTop: 5,
      },
    },
  })
);

interface IProps {
  label: string;
  onChange?: (file: any) => void;
  error?: any;
  required?: boolean;
  formFiles: any;
  tooltip?: string;
  value?: string;
  url?: string;
  disabled?: boolean;
}

export default function AttachFile({
  label = "",
  onChange,
  error,
  required = false,
  formFiles,
  value = "",
  tooltip,
  url = "",
  disabled,
}: IProps) {
  const [file, setFile] = useState<any>({ name: value });
  const classes = useStyles({ error, file });
  const [fileError, setFileError] = useState<string>();
  const [objectUrl, setObjectUrl] = useState<string>(url);
  const [open, setOpen] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeFile = () => {
    setFile({ name: "" });
    onChange && onChange(null);

    if (inputEl && inputEl.current) {
      inputEl.current.value = "";
      var ev = new Event("change", { bubbles: true });
      inputEl.current.dispatchEvent(ev);
    }
  };

  const handleChange = ({ target: { files, value } }: any) => {
    if (files.length === 0) return;

    // Check if this file already attached in different attahcmend field
    if (Object.values(formFiles).includes(value)) {
      setFileError("Please select a different ﬁle as this was already attached earlier");
      if (inputEl && inputEl.current) {
        inputEl.current.value = "";
      }
      return;
    }

    // Check file size
    const fileSize = (files[0].size / 1024 / 1024).toFixed(4);
    if (Number(fileSize) > 10) {
      setFileError("Max file size 10 MB");
      if (inputEl && inputEl.current) {
        inputEl.current.value = "";
      }
      return;
    }
    setFileError("");
    setObjectUrl(URL.createObjectURL(files[0]));
    setFile(files[0]);
    onChange && onChange(files[0]);
  };

  if (disabled) return null;

  return (
    <>
      <Box className={classes.root} p={2}>
        <Box color="textSecondary" display="flex" alignItems="center" width={[1, 1 / 2]}>
          {file?.name ? (
            <CheckCircle className={classes.checkIcon} />
          ) : error || fileError ? (
            <Error className={classes.errorIcon} />
          ) : (
            <FiberManualRecordIcon className={classes.pendingIcon} />
          )}

          <Box ml={1}>
            {label}
            {required && <span> *</span>}
          </Box>
        </Box>
        <Box display={file?.name ? "none" : "block"} width={1 / 2} textAlign="right">
          <input
            accept="image/*|.pdf"
            className={classes.input}
            id={label}
            type="file"
            onChange={handleChange}
            ref={inputEl}
            name={label}
          />
          <label htmlFor={label}>
            <ConditionalWrapper
              condition={isExisty(tooltip)}
              wrapper={(children: any) => (
                <CustomTooltip title={tooltip} aria-label={tooltip} placement="bottom" arrow>
                  {children}
                </CustomTooltip>
              )}
            >
              <Button variant="outlined" color="primary" component="span">
                Select file
              </Button>
            </ConditionalWrapper>
          </label>
        </Box>
        {file && file?.name && (
          <>
            <Box width={[1, 1 / 2]} textAlign="right">
              <Chip
                className={classes.selectedFileChip}
                color="primary"
                label={file.name}
                clickable
                onClick={handleClickOpen}
                onDelete={removeFile}
                icon={<Attachment />}
              />
            </Box>
            <ImageDialogViewer src={objectUrl} title={file.name} open={open} close={handleClose} />
          </>
        )}
        {fileError && (
          <Box color={theme.palette.error.main} width={1}>
            {fileError}
          </Box>
        )}
      </Box>
    </>
  );
}
