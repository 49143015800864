import React from 'react';
import { ReactComponent as Maf } from 'assets/svgs/MAF_Logo.svg';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

export const Footer = () => (
  <Box display="flex" bgcolor="#31261d" py={2} flexShrink="0" height="67px">
    <Container>
      <Box display="flex" alignItems="center">
        <Box height="40px" width="146px" mr={4}>
          <Maf />
        </Box>
        <Box color="white" fontSize="11px" ml="auto">
          © 2020 Carrefour. All rights reserved.
        </Box>
      </Box>
    </Container>
  </Box>
);
export default Footer;
