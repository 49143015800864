import { Button } from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";

export const CustomButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: "44px",
    backgroundColor: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#dadada",
    },
    borderRadius: 4,
    fontSize: 14,
    padding: "14px 12px",
    border: "1px solid",
  },
}))(Button);

export default CustomButton;
