import React from "react";
import { Controller } from "react-hook-form";
import { TextField, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  name: string;
  label: String;
  control: any;
  validation?: any;
  error?: any;
  options?: any[];
  defaultValue?: string;
  onChange?: (value: any) => void;
  disabled: boolean;
}

const Select: React.FC<Props> = ({
  name,
  validation,
  control,
  label,
  error,
  options = [],
  defaultValue = "",
  onChange,
  disabled = false,
}: Props) => {
  const cx = useStyles();
  const handleChange = ([
    ,
    {
      props: { value },
    },
  ]: any) => {
    onChange && onChange({ target: { value, name, type: "select" } });
    return { value };
  };

  return (
    <Controller
      name={name}
      rules={{ ...validation }}
      control={control}
      onChange={handleChange}
      defaultValue={defaultValue}
      as={
        <TextField
          select
          fullWidth
          disabled={disabled}
          label={label}
          variant="filled"
          className={cx.root}
          InputLabelProps={{ required: !!validation.required }}
          error={!!error}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          helperText={error ? error.message : ""}
        >
          {options.map(({ value, label }: any) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: "#F3F5F8 !important",
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.main,
    },
  },
}));

export default React.memo(Select);
