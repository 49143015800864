// graphql
import React, { useEffect, useReducer } from "react";
import useDelayedGql from "hooks/useDelayedGql";
import { Query } from "constants/gql";
import { getOr } from "lodash/fp";
import { DataContext, defaultContext } from "routes/context";

import { ContactProperties as IContact, ShopProperties } from "../@types/graphqlTypes";
import { useAuth0 } from "@auth0/auth0-react";

export default function DataProvider(props: any) {
  const { getAccessTokenSilently, user: auth0User, loginWithRedirect } = useAuth0();
  const [state, dispatch] = useReducer(reducer, defaultContext) as any;
  const callQuery = useDelayedGql(Query, {
    errorPolicy: "all",
    pollInterval: 300000,
    context: {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    },
  }); // poll every 5

  const getUserByEmail = async (email: string | null) => {
    try {
      const query = (await callQuery({ email: email })) as { data: IContact; errors?: any } | any;
      dispatch({
        type: "SET_USER",
        payload: {
          user: query.data,
          error: query.errors ? query.errors[0].message : undefined,
        },
      });
    } catch (e) {
      console.error(e);
      loginWithRedirect();
    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch({
          type: "SET_AUTH_DATA",
          payload: {
            token: await getAccessTokenSilently(),
            auth0User,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, state.token, auth0User]);

  useEffect(() => {
    if (state.email && state.token) getUserByEmail(state?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.email, state.token]);

  return (
    <DataContext.Provider
      value={{
        ...state,
        dispatch: dispatch,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case "SET_AUTH_DATA":
      const { token, auth0User } = action?.payload;
      let newState = {
        ...state,
        token,
      };

      if (auth0User) {
        newState.auth0User = auth0User;
      }

      return newState;

    case "SET_SHOP":
      const shop = getOr({}, "shop.node", action?.payload?.shop) as ShopProperties;
      return {
        ...state,
        shop: {
          data: shop,
          loading: !shop?.shop_id,
          error: action?.payload?.error,
        },
      };
    case "SET_USER":
      const contact = getOr({}, "contact.node", action?.payload?.user) as IContact;
      return {
        ...state,
        user: {
          data: contact,
          loading: !contact?.id,
          error: action?.payload?.error,
        },

        shop_id: contact?.shop_id,
        isLoggedIn: contact?.id ? true : false,
      };
    default:
      return { ...state, ...action };
  }
}
