import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import prettysize from "prettysize";
import { ReactComponent as ImageSvg } from "assets/svgs/image.svg";
import { ReactComponent as PdfSvg } from "assets/svgs/pdf.svg";
import { ReactComponent as WordSvg } from "assets/svgs/word.svg";
import { ReactComponent as ExcelSvg } from "assets/svgs/excel.svg";
import If from "utils/If";

interface IProps {
  name: string;
  type: string;
  size: number;
  invert?: boolean;
  url?: string;
  onRemove?: () => void;
}

const getFileTypeSVG = (type: string = "", format: string) => {
  if (type.startsWith("image") || type.includes("img")) return <ImageSvg />;
  if (type === "application/pdf") return <PdfSvg />;
  if (format.includes("doc")) return <WordSvg />;
  if (format.includes("xls")) return <ExcelSvg />;
  if (format.includes("png")) return <ImageSvg />;
  if (format.includes("pdf")) return <PdfSvg />;
  else return <ImageSvg />;
};

export default function FileBox({ name, type, size, invert = false, onRemove, url }: IProps) {
  const classes = useStyles();
  var sizeInMB = size;
  const clickHandler = () => url && window.open(url);
  return (
    <Box
      px={0.5}
      width={1 / 2}
      maxWidth="274px"
      onClick={clickHandler}
      className={url ? classes.url : classes.container}
    >
      <Box
        height="47px"
        bgcolor={invert ? "#F3F5F8" : "#ffffff"}
        display="flex"
        px={1.5}
        alignItems="center"
        mt={1}
      >
        {getFileTypeSVG(type, name.substr(name.lastIndexOf(".") + 1))}
        <Box mx={1.5} display="flex" flexDirection="column" overflow="hidden">
          <Box
            fontWeight="600"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            title={name}
          >
            {name}
          </Box>
          <Box>{prettysize(Math.round(sizeInMB))}</Box>
        </Box>
        <If test={onRemove}>
          <Box ml="auto">
            <IconButton className={classes.removeBtn} onClick={onRemove}>
              <CloseIcon />
            </IconButton>
          </Box>
        </If>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeBtn: {
      padding: 0,
    },
    container: {},
    url: {
      "&:hover": {
        cursor: "pointer",
        opacity: 0.8,
      },
    },
  })
);
