import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      light: "#3e7bb8",
      main: "#0E5AA7",
      dark: "#093e74",
    },
    secondary: {
      light: "#f15052",
      main: "#dd2326",
      dark: "#a6191b",
    },
    action: {
      active: "rgba(0, 0, 0, 0.64)",
      hover: `rgba(133, 54, 77, 0.2)`,
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)",
      disabled: "rgba(0, 0, 0, 0.20)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#4d4d4d",
      secondary: "#4d4d4d",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontWeight: 700,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 2,
        height: 48,
        fontSize: 14,
        transition:
          "color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out",
      },
      containedPrimary: {
        color: "#fff",
      },
      contained: {
        boxShadow: "none",
      },
      outlined: {
        boxShadow: "none",
      },
      text: {
        boxShadow: "none",
        fontWeight: 600,
      },
    },
    MuiRadio: {
      root: {
        padding: 2,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 2,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "0.9rem",
        lineHeight: "unset",
      },
    },
  },
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontFamily: '"Montserrat",Roboto,sans-serif',
  },
});
