import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import COLORS from "theme/colors";
import grey from "@material-ui/core/colors/grey";
import theme from "theme";

interface Styles {
  type: string;
  [key: string]: any;
}

interface TypesMapping {
  success?: string;
  fail?: string;
  info?: string;
  pending?: string;
  default?: string;
  [key: string]: any;
}

interface ChipStyles extends WithStyles<typeof styles> {
  type: string;
  semiRounded: boolean;
  fullWidth: boolean;
}

interface IProps extends ChipProps {
  type: "success" | "fail" | "info" | "pending" | "default";
  label: string;
  semiRounded?: boolean;
  fullWidth?: boolean;
}

// Like https://github.com/brunobertolini/styled-by
const styledBy = (property: string, mapping: TypesMapping) => (props: Styles) =>
  mapping[props[property]];

const styles = {
  root: {
    background: styledBy("type", {
      success: COLORS.green,
      fail: COLORS.rose,
      info: COLORS.picasso,
      pending: COLORS.navajowhite,
      default: grey[100],
    }),
    color: styledBy("type", {
      success: "white",
      fail: "4D4D4D",
      info: "black",
      pending: "#4D4D4D",
      default: "#4D4D4D",
    }),
    borderRadius: styledBy("semiRounded", {
      true: 8,
      false: 20,
    }),
    width: styledBy("fullWidth", {
      true: "100%",
      false: "auto",
    }),
    height: "auto",
    padding: "10px 16px",
    position: "relative" as "relative",
    "& .MuiChip-deleteIcon": {
      position: "absolute" as "absolute",
      right: "2px",
      backgroundColor: "transparent",
      transition:
        "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      display: "none",
    },
    "&.MuiChip-clickable:hover": {
      "& .MuiChip-deleteIcon": {
        display: "block",
        color: "white",
      },
      "& .MuiChip-label": {
        width: "calc(100% - 16px)",
      },
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 500,
    padding: 0,
  },
};

const StyledChip = withStyles(
  styles
)(({ classes, type, semiRounded, fullWidth, ...other }: ChipStyles) => (
  <Chip className={classes.root} {...other} classes={{ label: classes.label }} />
));
export default function CustomChip({
  type,
  label,
  semiRounded = false,
  fullWidth = false,
  ...rest
}: IProps) {
  return (
    <StyledChip
      type={type}
      label={label}
      semiRounded={semiRounded}
      fullWidth={fullWidth}
      {...rest}
    />
  );
}
