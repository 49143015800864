import React, { useEffect, useState } from "react";
import {
  InputBase,
  FormControl,
  InputLabel,
  Box,
  Button,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import { createStyles, Theme, makeStyles, withStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import FileBox from "./FileBox";
import clsx from "clsx";

const FILE_SIZE_LIMIT = 5242880;
const INFO_UPLOAD_TOOLTIP =
  "You can upload 4 files max (docx, xlsx, jpg, pdf) with a maximum size 5MB.";
const MAX_FILES_TOOLTIP = "You reached the maximum numbers of file attachments.  ";
interface IProps {
  rows?: number;
  label: string;
  error?: any;
  required?: boolean;
  onChange?: (value: any) => void;
  value?: any;
}

export default function TextArea({
  rows = 4,
  label,
  error,
  onChange,
  required,
  value: fieldValue,
}: IProps) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [filesError, setFilesError] = useState("");
  const [files, setFiles] = useState<HTMLInputElement[]>([]);

  useEffect(() => {
    setFilesError(error);
  }, [error]);

  useEffect(() => {
    setValue(fieldValue.value);
    setFiles(fieldValue.files);
  }, [fieldValue]);

  const handleFileChange = ({ target: { files: inputFiles } }: any) => {
    const newFiles = [...files, ...inputFiles];

    if (Array.from(inputFiles).some((file: any) => file.size > FILE_SIZE_LIMIT)) {
      setFilesError("File size is greater than 5MB");
      return;
    }
    if (newFiles.length > 4) {
      setFilesError("Maximum of four files is only allowed");
      return;
    }
    // collect the selected file names
    const fileNames = Array.from(inputFiles).map((file: any) => file.name);
    if (Object.keys(files).some((i: any) => fileNames.includes(files[i].name))) {
      setFilesError("File is already attached");
      return;
    }
    setFilesError("");
    setFiles(newFiles);
    onChange && onChange({ value, files: newFiles });
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange({ value: e.target.value, files });
  };

  const removeFile = (name: string) => () => {
    const updatedFiles = files.filter((file: any) => file.name !== name);
    setFiles(updatedFiles);
    onChange && onChange({ value, files: updatedFiles });
  };

  return (
    <>
      <Box
        bgcolor="#F3F5F8"
        className={clsx(classes.underline, {
          [classes.error]: !!filesError,
        })}
        px={1.5}
        pt={1}
      >
        <FormControl fullWidth={true} error={!!filesError}>
          <InputLabel htmlFor="component-simple">
            {label}
            {required ? "*" : ""}
          </InputLabel>
          <Box pt={2}>
            <InputBase
              id="component-simple"
              value={value}
              onChange={onInputChange}
              multiline
              className={classes.input}
              rows={rows}
            />
          </Box>
          <Box mb={1} display="flex" flexWrap="wrap">
            {files.map((file) => (
              <FileBox
                name={file.name}
                size={file.size}
                type={file.type}
                key={file.name}
                onRemove={removeFile(file.name)}
              />
            ))}
          </Box>
        </FormControl>
      </Box>
      {filesError && <FormHelperText error>{filesError}</FormHelperText>}
      <Box mt={2}>
        <input
          accept="image/*,.pdf,.docx,.doc,.xlsx"
          className={classes.fileInput}
          id="icon-button-file"
          type="file"
          multiple={true}
          onChange={handleFileChange}
          disabled={files.length > 3}
        />
        <HtmlTooltip
          title={files.length > 3 ? MAX_FILES_TOOLTIP : INFO_UPLOAD_TOOLTIP}
          placement="bottom-start"
        >
          <label htmlFor="icon-button-file">
            <Button className={classes.attachBtn} component="span" disabled={files.length > 3}>
              <AttachFileIcon />
              Attach file
            </Button>
          </label>
        </HtmlTooltip>
      </Box>
    </>
  );
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    borderRadius: 4,
    fonts: 10,
    textAlign: "center",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    underline: {
      borderBottom: "2px solid #004280",
      transition: theme.transitions.create("border-bottom-color", {
        duration: theme.transitions.duration.shorter,
      }),
      "&:focus-within": {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    error: {
      borderBottom: `2px solid ${theme.palette.secondary.main} !important`,
    },
    input: {
      width: "100%",
    },
    fileInput: {
      display: "none",
    },
    attachBtn: {
      textTransform: "none",
      "& .MuiSvgIcon-root": {
        transform: "rotate(45deg)",
        marginRight: theme.spacing(1),
      },
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
      },
    },
    tooltip: {
      backgroundColor: "pink",
    },
  })
);
