import React from "react";
import ReactDOM from "react-dom";
import Auth0Provider from "utils/AuthProvider";
import { ThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/core/styles";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";

import * as serviceWorker from "./serviceWorker";
import theme from "./theme/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import Router from "./routes";
//import 'config/i18n';
import "./polyfills";
import "./font.css";
import initGTM from "config/GTM";
import "config/app_insights";
console.log({
  endpoint: process.env.REACT_APP_BE_ENDPOINT,
  auth0: process.env.REACT_APP_AUTH_ENDPOINT,
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_GQL_ENDPOINT,
  credentials: "include",
  cache: new InMemoryCache({
    freezeResults: true,
  }),
});

window.addEventListener("pageshow", function (event) {
  if (event.persisted) {
    console.log("Page was loaded from cache.");
    window.location.reload();
  }
});

initGTM();
ReactDOM.render(
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <Auth0Provider>
          <Router />
        </Auth0Provider>
      </ApolloProvider>
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
