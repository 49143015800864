import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const AuthenticatedRoute = ({ component: Component, ...rest }: any) => {
  const { isAuthenticated, isLoading } = useAuth0();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoading && !isAuthenticated) {
          return <Redirect to="/" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default AuthenticatedRoute;
