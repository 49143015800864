import React from "react";
import { AttachFile } from "components";
import { Controller } from "react-hook-form";
import { isExisty } from "utils/helpers.util";

export default function File({
  name,
  control,
  label,
  error,
  validation = {},
  formFiles,
  value = "",
  url = "",
  tooltip,
  disabled = false,
}: any) {
  return (
    <Controller
      name={name}
      rules={validation}
      defaultValue={value}
      control={control}
      onChange={([value]) => ({ value })}
      as={
        <AttachFile
          label={label}
          error={error && error.message}
          required={isExisty(validation.required)}
          formFiles={formFiles}
          value={value}
          tooltip={tooltip}
          url={url}
          disabled={disabled}
        />
      }
    />
  );
}
