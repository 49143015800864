// fragments
import { gql } from "apollo-boost";

export const baseUserFragment = gql`
  fragment CommonProperties on ContactProperties {
    id
    title
    firstname
    lastname
    email
    phone
    city
    address
    post_code
    country
    shop_id
    company
    account_name
    categories
    sku
    compliance_jira_id
    finance_jira_id
    createdate
    sales_channel
    deal_creation_date
    contact_owner
    terms_verified
    email_verified
    otp_verified
  }
`;
export const baseShopFragment = gql`
  fragment CommonProperties on ShopProperties {
    shop_id
    shop_name
    vat_number
    shop_state
    tax_identification_number
    identification_number
    corporate_name
    shipping_zones
    shipping_country
    trnno
    currency
    shipping_types
    free_shipping
    date_created
    offers_count
    contactInformations {
      city
    }
    deliveredByMaf
    fulfilmentMode
    bank {
      owner
      bic
      iban
      bank_name
      bank_city
    }
    documents {
      file_name
      id
      type
    }
  }
`;
