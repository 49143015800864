import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Box, Menu, MenuItem, Fade, Divider, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { DataContext } from "routes/context";
// local
import { Text } from "../../common.styles";
import theme from "theme";
import { ContactProperties as IContact } from "../../@types/graphqlTypes";

import { ReactComponent as UserSvg } from "assets/svgs/user.svg";

export default function UserAvatar() {
  const history = useHistory();
  const { logout, isAuthenticated } = useAuth0();
  const { user: userState } = useContext(DataContext);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | undefined | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoute = (route: string, refresh: boolean = false) => {
    handleClose();
    history.push(route);
    refresh && window.location.replace(route);
  };

  const handleLogout = () => {
    logout();
  };

  const user = userState.data as IContact;
  const userIsVerified = user?.email_verified && user?.otp_verified && user?.terms_verified;

  if ((!userIsVerified && isAuthenticated) || (!user?.firstname && !user?.id && isAuthenticated))
    return (
      <Button color="secondary" onClick={handleLogout}>
        Logout
      </Button>
    );

  const name = `${user?.firstname} ${user?.lastname}`;

  return (
    <>
      <Fade in={isAuthenticated}>
        <Box display="flex" alignItems="center" className={classes.avatarBox} onClick={openMenu}>
          <Box width="28px" height="28px" component="span" display="inline-block">
            <UserSvg />
          </Box>

          <Box mx={1} flexDirection="column" display={["none", "flex"]}>
            <Text fontSize={10} fontWeight={500}>
              Hi, {name}
            </Text>
            <Text fontSize={12} color={theme.palette.primary.main} fontWeight={500}>
              {user?.company}
            </Text>
          </Box>
          <ExpandMore />
        </Box>
      </Fade>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
      >
        <MenuItem onClick={() => handleRoute("/portal/account")}>Account Settings</MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    avatarBox: {
      cursor: "pointer",
      padding: theme.spacing(1),
      "&:hover": {
        background: "#eee",
        transition: "background .4s",
      },
    },
    menu: {
      borderRadius: "0 0 8px 8px",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
      width: "186px",
      marginTop: "17px",
      "& .MuiListItem-button": {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 500,
      },
      "& .MuiListItem-button:hover": {
        backgroundColor: "white",
        color: theme.palette.primary.main,
      },
    },
  })
);
