import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PasswordRulesPopper from './PasswordRulesPopper';
import { isExisty } from 'utils/helpers.util';

export default function Password({ name, label, validation, error, register }: any) {
  const [showPassword, setShowPassword] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPoper, setOpenPoper] = useState(false);

  const showPoper = (event: any) => {
    setOpenPoper(!openPoper);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <PasswordRulesPopper open={openPoper} anchorEl={anchorEl} />
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="password" error={isExisty(error)} required={true}>
          {label}
        </InputLabel>
        <OutlinedInput
          id="password"
          type={showPassword ? 'text' : 'password'}
          name={name}
          label={label}
          inputRef={register(validation)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          error={!!error}
          onFocus={showPoper}
          onBlur={showPoper}
        />
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormControl>
    </>
  );
}
