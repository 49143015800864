import React, { ReactNode } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LoadingBox from "components/LoadingBox/LoadingBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      position: "relative",
    },
    loadingBox: {
      content: '""',
      width: "100%",
      height: "100%",
      position: "absolute",
      background: "#ffffffe0",
      top: 0,
      zIndex: 2,
    },
    checkIcon: {
      color: theme.palette.success.main,
      width: "60px",
      height: "60px",
    },
  })
);

interface IProps {
  children: ReactNode;
  title: string;
  successNode?: ReactNode;
  width?: string;
  loading?: boolean;
  success?: boolean;
}

export const FormLayout: React.FC<IProps> = ({
  children,
  title,
  successNode,
  width = "60%",
  loading = false,
  success = false,
}: IProps) => {
  const classes = useStyles();

  return (
    <>
      <Box height="auto" flex="1" bgcolor="#FAFAFA">
        <Container>
          <Box height="100%" py={5} width={[1, 1, 1, width]} mx="auto">
            <Box mb={2}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                {title}
              </Typography>
            </Box>
            <Card className={classes.card}>
              <CardContent>{children}</CardContent>
              {(loading || success) && (
                <LoadingBox loading={loading} success={success && !loading}>
                  <Fade in={success && !loading}>
                    {successNode ? (
                      <>{successNode}</>
                    ) : (
                      <CheckCircleIcon className={classes.checkIcon} />
                    )}
                  </Fade>
                </LoadingBox>
              )}
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FormLayout;
