// https://www.apollographql.com/docs/ios/fragments/
import { gql } from "apollo-boost";
import { baseUserFragment, baseShopFragment } from "./fragments";

export const Query = gql`
  query($email: String) {
    contact(email: $email) {
      node {
        ...CommonProperties
      }
    }
  }
  ${baseUserFragment}
`;

export const ShopQuery = gql`
  query($shopId: Int) {
    shop(id: $shopId) {
      node {
        ...CommonProperties
      }
    }
  }

  ${baseShopFragment}
`;

export const JiraQuery = gql`
  query($financeId: String, $complianceId: String) {
    complianceJira: jira(id: $complianceId) {
      node {
        issueKey
        currentStatus {
          status
          statusDate {
            friendly
            iso8601
          }
        }
      }
    }
    financeJira: jira(id: $financeId) {
      node {
        issueKey
        currentStatus {
          status
          statusDate {
            friendly
            iso8601
          }
        }
      }
    }
  }
`;
