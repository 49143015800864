import React from "react";
import { Link as MuiLink, Breadcrumbs, Typography, Box, BoxProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLocation } from "react-router-dom";
import Link from "components/Link";

interface IProps extends BoxProps {
  routes: any;
}

export const CustomBreadcrumbs = ({ routes, ...rest }: IProps) => {
  const location = useLocation();
  const cx = useStyles();

  return (
    <Box mb={3} {...rest}>
      <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
        {routes.parents.map((parent: any) => (
          <Link to={parent.path} key={parent.path}>
            <MuiLink
              component="span"
              color="primary"
              variant="body2"
              underline="none"
              className={cx.link}
              key={parent.path + parent.title}
            >
              {parent.title}
            </MuiLink>
          </Link>
        ))}
        <Typography color="textPrimary" variant="body2">
          {routes.childs.find((child: any) => child.path === location.pathname)?.title || ""}
        </Typography>
      </Breadcrumbs>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 500,
    textTransform: "capitalize",
  },
}));

export default CustomBreadcrumbs;
